<template>
  <div style="display:flex;flex-direction:column;">
    <div class="help-header-style">{{title}}</div>
    <div class="help-description-style">{{description}}</div>
  </div>
</template>
<script>
export default {
  name: "HelpHeaderAndDescription",
  props: ["title", "description"]
};
</script>
<style>
.help-header-style {
  margin-top: 30px;
  font-size: 46px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
}
.help-description-style {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #5e6b79;
  margin-bottom: 40px;
  margin-top: 10px;
}
</style>
