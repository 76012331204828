<template>
  <div>
    <div style='position: fixed; left: 0; width: 33%;  background-color: #fff; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>


    <div class="pageContainer" style="display: flex; background-color: #f4f5f7!important;">


      <transition name="modal" v-if="isVideoModal">
        <div  @click="isVideoModal = false" class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; background-color: rgba(0, 0, 0, .8)!important; ">
          <div class="modal-container" style="width: 900px !important; padding:0!important;; max-height: 506px">

            <div >
              <iframe
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                width="900" height="506"
                frameborder="0"
                scrolling="no"
                :src="'https://api.bionluk.com/general/video/' +  '10734587'+'x'+ '10734587' +'/custom_video/'"
              >
              </iframe>
            </div>

          </div>
        </div>
      </transition>

    <div style="background:#f4f5f7;">
      <div style="display:flex;flex-direction:row; margin: 0 auto; width: 1170px;">
        <div style="display:flex;flex-direction:column; align-items:flex-end;  background-color:#ffffff;width: 285px; ">
          <div style="display:flex;flex-direction:column;  margin-top:42px; ">
            <div
              v-for="(item,index) in leftBarItemList"
              :key="index"
              :class="item.title === selectedRow ? 'selected-category-row selected-category-item' : 'category-item'"
              style="padding-top:10px;padding-bottom:10px;margin-bottom:10px; cursor:pointer;"
              @click="itemClick(item.title)"
            >{{item.title}}</div>
          </div>
        </div>


        <div v-if="!pageLoaded" style="padding-top:130px; height: 280px; width: 100%;">
          <div class="loader" style="text-align: center; margin: 0 auto;"></div>
        </div>
        <div
          v-else-if="selectedRow === 'Tüm Destek Taleplerin'"
          style="width: 100%; padding-left:60px;  background-color: #f4f5f7;padding-bottom:70px; min-height:300px;"
        >
          <HelpNavBar
            :contentData="navBarData"
            :showButton="true"
            style=" margin-top:45px;"
          />
          <HelpHeaderAndDescription
            :title="'Tüm Destek Talepleri'"
            :description="'Sevgili ' + $store.state.user.firstname + ', destek taleplerini buradan takip edebilirsin.' "
          />

          <div
            v-for="(item,index) in tickets"
            :key="index"
            class="ticket-row"
            @click="ticketClick(item.uuid)"
          >
            <div style="display:flex;align-items:center;">
              <div
                style="padding-right:10px; width: 160px; text-align: left "
                class="date-text"
                :class="item.is_seen ? 'disabled-text':''"
              >{{ item.created_at | customDateFormat('dd MMMM, yyyy (HH:mm)') }}</div>
              <div style=" border-right: solid 1px #eaedf2; height:80px;margin-right:25px;"></div>

              <img :src="item.avatar_url" style="width:36px;height:36px;margin-right:15px;border-radius:30px;"/>

              <div
                class="ticket-subject-text"
                :class="item.is_seen ? 'disabled-text':''">{{item.title}}</div>
            </div>
            <div
              style="text-align: right; margin-right:7px;margin-left: auto;"
              class="date-text"
              :class="item.is_seen ? 'disabled-text': item.statusText === 'Yanıtlandı' ? 'answered-text':''">
              {{ item.status_help_text}}{{item.last_message_at | customDateFormat('dd MMMM, yyyy (HH:mm)') }}
            </div>
            <div @click="deleteTicket(item.uuid)" @click.stop >
              <img src="https://gcdn.bionluk.com/site/c/ic-trash.svg" onload="SVGInject(this)" class="delete-uploaded-file-img"/>
            </div>
          </div>
        </div>

        <div
          style="width: 100%; padding-left:60px;  background-color: #f4f5f7;padding-bottom:70px; min-height:300px;"
          v-else
        >
          <HelpNavBar
            :contentData="navBarData"
            :showButton="true"
            style=" margin-top:45px;"
          />
          <HelpHeaderAndDescription
            :title="progress.filter(x=> x.step === currentStep)[0].title"
            :description="progress.filter(x=> x.step === currentStep)[0].description"
          />

          <div v-show="currentStep === 1" >

            <template v-if="!showTicketCategories">
              <div v-if="personaLoading" class="persona-question-box">
                <loader/>

              </div>
              <div v-else class="persona-question-box">
                {{persona_info.question}}

                <div v-if="!showPersonaAnswer" style="margin-top: 40px; display: flex; justify-content: flex-end">
                 <custom-button @click="personaAnswerProcess('no')"  button-type="border" style="width: 250px; padding-bottom: 3px; height: 44px;">Hayır, başka bir sorum var</custom-button>
                 <custom-button @click="personaAnswerProcess('yes')" button-type="green" style="margin-left:30px; width: 100px; padding-bottom: 3px; height: 44px;">Evet</custom-button>
                </div>
                <template v-else>

                  <div v-if="persona_info.persona_id === 1" style="font-size: 16px; color: #5e6b79; font-weight: 400; line-height: 1.75; margin-top: 40px;">
                    Sebebini merak ediyorsan senin için bir video hazırladık. <span class="hover-gg" @click="openVideoModal()">Göz atmak için tıkla!</span> 😉
                  </div>
                  <div v-else v-html="persona_info.answer" style="font-size: 16px; color: #5e6b79; font-weight: 400; line-height: 1.75; margin-top: 40px;"></div v-else>

                  <div v-if="persona_info.persona_id === 3" style="font-size: 16px; color: #5e6b79; font-weight: 400; line-height: 1.75; margin-top: 10px;">
                    Detaylar için sana özel hazırladığımız <span class="hover-gg" @click="openVideoModal()">videoya göz at!</span> 😉
                  </div>
                  <template v-if="persona_info.options.length > 0">
                    <custom-select :label="persona_info.select_title"
                                   id="disapprovedgig"
                                   :options="persona_info.options"
                                   :customOption="{name: 'title', value:'id'}"
                                   v-model="persona_info.selected_option"
                                   style="width: 393px; margin-bottom: 42px; margin-top:30px;"/>


                    <div v-if="persona_info.selected_option && getPersonaOption.options && getPersonaOption.options.length">
                      <div  class="request-warning">
                        <div class="request-warning-inside">
                          <div>
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-warning.svg"  onload="SVGInject(this)" class="warning-icon">
                          </div>
                          <div style="margin-left: 15px;">
                            <p class="warning-head">İlanın onaylanmadı.</p>
                            <p class="warning-sub-head">İlanında bazı sorunlar tespit ettik. Altta belirtilmiş sorunları gidererek tekrar yayına alabilirsin.</p>

                            <p class="disapproval-row" v-for="disapproval in getPersonaOption.options">
                              <span v-if="disapproval.disapprovalTitle !== 'Editörümüzün Mesajı:'" class="warning-description-html" >{{disapproval.disapprovalDescription}}</span>
                              <span v-if="disapproval.disapprovalTitle && disapproval.disapprovalTitle === 'Editörümüzün Mesajı:'" class="warning-description-html" v-html="'Editörümüzün Mesajı: ' + disapproval.disapprovalDescription"></span>
                              <router-link class="link" target="_blank" v-if="disapproval.disapprovalSupportLink" :to="disapproval.disapprovalSupportLink"> [ayrıntılı bilgi]</router-link>
                            </p>

                          </div>
                        </div>
                      </div>

                      <CustomButton @click="$router.push(getPersonaOption.redirect_button_link)">{{ getPersonaOption.redirect_button_text }}</CustomButton>
                    </div>



                  </template>

                </template>

              </div>
              <div v-show="showYeterlimi" class="yeterlimi">
                <div class="support-icon-container">
                  <img
                    src="https://gcdn.bionluk.com/site/cicons/ic-thumb.svg"
                    onload="SVGInject(this)"
                    class="support-icon"
                  />
                </div>
                <p class="y-text">Bu cevap sana faydalı oldu mu?</p>
                <button @click="rateArticle('positive')" class="y-button">Evet, yeterli.</button>
                <button @click="rateArticle('negative')" class="y-button">Hayır, hala yardıma ihtiyacım var.</button>
              </div>
            </template>
            <div v-if="showTicketCategories" class="content-container">
              <div class="progress-bar-container">
                <div
                  class="progress-bar-inner"
                  :class="progressPercent === 0 ? 'percent-0': 'percent-33'"
                ></div>
              </div>
              <div style="display:flex;flex-wrap:wrap;justify-content:space-between;">
                <div
                  class="step1-card-item"
                  v-for="(item,index) in ticketCategories"
                  :key="index"
                  :class="index%2 === 0 ? (item.id === selectedTicketCategoryId ? 'margin-right-57 selected-step1-data' : 'margin-right-57 unselected-step1-data')
                                                                    : (item.id === selectedTicketCategoryId ? 'selected-step1-data' :'unselected-step1-data') "
                  @click="selectTicketCategory(item)"
                >{{item.title}}</div>
              </div>
              <div style="display:flex;margin-top:30px;justify-content:flex-end;align-items:center;">
                <div class="cancel-button" @click="cancelClick">Geri</div>
                <div
                  class="continue-button"
                  :class="progressPercent === 0 ?  'unselected-continue-button':'selected-continue-button'"
                  @click="continueClick"
                >Devam Et</div>
              </div>
            </div>
          </div>

          <div v-show="currentStep === 2" class="content-container">
            <div class="progress-bar-container">
              <div class="progress-bar-inner percent-66"></div>
            </div>

            <Accordion
              v-for="(faq, i) in faqs"
              :accordion="faq"
              :impression-u-u-i-d="impression_id"
              :index="i"
              :key="i"
              :open="selectedFaqId === faq.id"
              @toggleOpen="toggleOpen(faq)"
              style="cursor:pointer;"
            />



            <div v-if="orders.length > 0 && (selectedTicketCategoryId === '30' || selectedTicketCategoryId === '33' || selectedTicketCategoryId === '22')">

              <div style="display:flex;margin-top:30px;align-items:center;">
                <div class="cancel-button" @click="cancelClick">Geri</div>
              </div>


              <div class="text-area-description">
                İlgili siparişin Çözüm Merkezi'ne gitmek için lütfen bir sipariş seç.
              </div>

              <div v-if="orderBodyLoaded">
                <router-link :to="`/orders/${order.order_id}?tab=support`" style="text-decoration: none!important;" v-for="(order, index) in orders" :key="index" class="row-wrapper" :style="`border: solid 1px ${order.borderColor}`" >
                  <div class="row-body">
                    <div class="row-item-image" style="position: relative">
                      <div class="info-icon-wrapper" :data-balloon="`Ben, ${order.title}`" data-balloon-pos="up" style="position: absolute; top: 5px; left: 5px; display: flex; align-items: center;">
                        <div style="width: 24px; text-align: center">
                          <img src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="info-icon"/>
                        </div>
                      </div>
                      <img :src="order.image_url" style="width: 151px; height: 84px;border: solid 1px #eff3f8;border-radius: 5px;"/>
                    </div>
                    <div class="row-item-dashed">
                      <div style="margin: 15px; position: relative">
                        <div style="font-size: 11px; color: #8b95a1;">{{orderType === 'buying' ? 'FREELANCER' : 'ALICI'}}</div>
                        <div style="display: flex; margin-top: 6px">
                          <div style="width: 34px; height: 34px;">
                            <img :src="order.kim.avatar_url" style="width: 34px; height: 34px; border-radius: 50%;"/>
                          </div>
                          <div style="margin-left: 11px; font-size: 12px;">
                            <p style="color: #2d3640; margin-top: 2px;">{{ order.kim.username }}</p>
                            <p style="color: #5e6b79; margin-top: 4px; text-overflow: ellipsis; line-height: 1.3; width: 130px; white-space: pre; overflow-x: hidden">{{ order.kim.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-item-dashed">
                      <div style="margin: 15px">
                        <div style="font-size: 11px; color: #8b95a1;">SİPARİŞ ve TESLİM TARİHİ</div>
                        <div style="display: flex; margin-top: 6px">
                          <div class="basket-wrapper">
                            <div style="margin: auto">
                              <img src="https://gcdn.bionluk.com/site/cicons/ic-basket.svg" onload="SVGInject(this)" class="basket-icon"/>
                            </div>
                            <div v-if="order.due_on" class="inner-icon-wrapper">
                              <div style="margin: auto">
                                <img src="https://gcdn.bionluk.com/site/cicons/ic-package.svg" onload="SVGInject(this)" class="inner-icon"/>
                              </div>
                            </div>
                          </div>
                          <div style="margin-left: 12px" :style="order.due_on ? null : 'display: flex; flex-direction: column; justify-content: center'">
                            <div class="order-date">
                              {{ Number(order.payed_at) | customDateFormat('dd MMM yyyy') }}
                            </div>
                            <div v-if="order.due_on" class="order-date" style="margin-top: 7px">
                              {{ Number(order.due_on) | customDateFormat('dd MMM yyyy') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row-item-info">
                      <div style="text-align: right">
                        <span class="order-price" :class="order.status !== '-1' ? 'active' : null">{{order.total}}</span>
                        <span class="order-currency" :class="order.status !== '-1' ? 'active' : null">TL</span>
                      </div>
                      <div class="status-text" style="margin-top:10px" :style="`color: ${order.statusTextColor}; border: solid 1px ${order.statusTextColor}`">
                        {{order.statusText}}
                      </div>
                    </div>
                  </div>
                </router-link>

              </div>
              <loader v-else style="margin-top: 50px"/>

              <div v-if="!orderSeemoreLoader && orderSeemorediv && orderBodyLoaded" @click="retrieveOrders(true)"
                   style="cursor: pointer; margin-top:50px;font-size: 14px; font-weight: 600;text-align: center; color: #2d3640;">
                Daha fazla gör +
              </div>
              <loader v-else-if="orderSeemoreLoader" style="margin-top: 20px"/>

            </div>


            <div v-else-if="selectedTicketCategoryId === '46'">
              <div class="text-area-description">
                Sana daha iyi yardımcı olabilmemiz için lütfen üstteki adımlardan birini uygula.
              </div>
              <div style="display:flex;margin-top:30px;justify-content:flex-start;align-items:center;">
                <div class="cancel-button" @click="cancelClick">Geri</div>
              </div>
            </div>

            <template v-else>

              <div v-if="faqs.length > 0" class="text-area-description">
                Yaşadığın problem yukarıdakilerden biri değilse destek talebini detaylı biçimde açıklaman, sana en hızlı şekilde geri dönebilmemiz için önemli.
              </div>

              <div>
              <textarea
                maxlength="600"
                v-model="message"
                placeholder="Mesajın..."
                class="help-problem-detail"
                :class="message === '' ? 'empty-text-area':'filled-text-area'"
              ></textarea>
              </div>

              <div style="display: flex; justify-content: space-between">
                <div style="display:flex;margin-bottom:16px; align-items: center; height: 20px;">
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                  <div @click="chooseFiles('support_upload')" class="add-attachment-text" >Dosya Ekle</div>
                  <input id="support_upload" style="visibility: hidden" type="file" @change="onFileChange($event, Constants.UPLOAD_TYPES.TICKET)">

                </div>
                <div style="color: #8b95a1; font-size: 13px; font-weight: 500">
                  {{message.length}} / <span style="color: #2d3640">600</span>
                </div>
              </div>


              <div v-for="file in supportFiles" style="margin-bottom: 15px;">
                <div style="display: flex; align-items: center">
                  <div v-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" @click="cancelUpload(file.stamp)">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>
                  <div v-else @click="deleteFile(file.externalID, 'ticket')">
                    <img  src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)" class="delete-icon">
                  </div>

                  <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                  <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                  <div style="display: flex; flex-direction: column; margin-left: 5px;">
                    <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                    <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                  </div>
                </div>
              </div>



              <div style="display:flex;margin-top:30px;justify-content:flex-end;align-items:center;">
                <div class="cancel-button" @click="cancelClick">Geri</div>
                <div
                  class="continue-button"
                  :class="message === '' || buttonLoading ?  'unselected-continue-button':'selected-continue-button'"
                  @click="continueClick"
                >Destek Talebi Oluştur</div>
              </div>
            </template>
          </div>

          <div v-show="currentStep === 3" class="content-container">
            <div class="progress-bar-container">
              <div class="progress-bar-inner percent-100"></div>
            </div>

            <div
              class="ticket-submit-description"
            >Oluşturduğun destek taleplerini, <span>Yardım Merkezi</span> ana sayfasının sağ üst köşesinde bulunan <span>Destek Taleplerim</span> bölümünden takip edebilirsin...</div>
            <div class="ticket-summary-header">Talep Özeti</div>
            <div style="  border-radius: 2px;border: dashed 1px #8b95a1; margin-bottom:22px;">
              <div class="ticket-summary-description">{{message}}</div>
            </div>


            <div v-for="file in supportFiles" style="margin-bottom: 15px;">
              <div style="display: flex; align-items: center">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/ic-attachment.svg" onload="SVGInject(this)" class="attach-icon">
                </div>

                <img v-if="file.icon_png" style="height: 24px; width: 19px; padding-left: 12px; " :src="file.icon_png">
                <img v-else-if="$store.state.percentCompleted.temp_icon_png[file.stamp]" style="height: 24px; width: 19px; padding-left: 12px; " :src="$store.state.percentCompleted.temp_icon_png[file.stamp]">
                <div style="display: flex; flex-direction: column; margin-left: 5px;">
                  <a target="_blank" :href="file.url" style="color: #2d3640; font-weight: 500; font-size: 13px;">{{ file.filename }}</a>
                  <progress v-if="$store.state.percentCompleted.ticket[file.stamp]" style="margin-top: 5px; margin-left: 0;" class="progressbar-purple" :value='$store.state.percentCompleted.ticket[file.stamp]' max="100"></progress>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import HelpNavBar from "../../../components/HelpNavBar";
import Accordion from "../../../components/Accordion";
import HelpHeaderAndDescription from "../../../components/HelpHeaderAndDescription";
import CustomButton from "@/pages/components/CustomButton";
import CustomSelect from "../../../components/CustomSelect";
export default {
  name: "src-pages-body-tickets-ticketList-v2",
  components: {
    HelpNavBar,
    Accordion,
    HelpHeaderAndDescription,
    CustomButton, CustomSelect
  },
  data() {
    return {
      isVideoModal:false,
      buttonLoading:false,
      pageLoaded: true,
      tickets:[],
      ticketCategories:[],
      selectedTicketCategoryId:null,
      faqs:[],
      persona:null,
      selectedFaqId:null,
      supportFiles:[],
      persona_info:{
        persona_id:null,
        options:[],
        selected_option:null,
      },
      showTicketCategories:true,
      showPersonaAnswer:false,
      showYeterlimi:false,
      impression_id:null,

      currentStep: 1,
      progressPercent: 0,
      progress: [
        {
          step: 1,
          title: "Yeni Destek Talebi Oluştur",
          description:
            "Sevgili " +
            this.$store.state.user.firstname +
            ", hangi konuda yardıma ihtiyacın var?"
          //   description: "Sevgili Ayse, hangi konuda yardıma ihtiyacın var?"
        },
        {
          step: 2,
          title: "Yeni Destek Talebi Oluştur",
          description:
            "Sevgili " +
            this.$store.state.user.firstname +
            ", hangi konuda yardıma ihtiyacın var?"
        },
        {
          step: 3,
          title: "Destek Talebin Oluşturuldu",
          description:
            "Sevgili " +
            this.$store.state.user.firstname +
            ", en kısa sürede sana geri döneceğimize emin olabilirsin. 😇"
        }
      ],
      leftBarItemList: [
        {
          title: "Tüm Destek Taleplerin"
        },
        {
          title: "Yeni Destek Talebi Oluştur"
        }
      ],
      selectedRow: "Tüm Destek Taleplerin",
      message: "",
      ticketMessage: "",

      orders:[],
      orderOffset: 0,
      orderType: 'buying',
      orderBodyLoaded: false,
      orderSeemoreLoader: false,
      orderTotalCount: 0,
      orderSeemorediv: false,
      activeTabTimeStamp:null,

      personaLoading:false
    };
  },

  methods: {
    openVideoModal(){
      this.isVideoModal = true;
      this.Helper.trackEvents.customEvent("custom_video_view", {title:'ilan_kabul_edilmedi', page:this.$store.state.componentMap.page,  impression_id:this.impression_id,});
    },

    rateArticle(t){
      if(t ==='negative'){
        this.showTicketCategories = true;
        this.showYeterlimi = false;
      } else if(t ==='positive'){
        this.$toasted.global.infoToast({
          description: 'Teşekkürler 🥰 '
        });
        this.showYeterlimi = false;
      }

      let trackObj = {
        impression_id:this.impression_id,
        persona_id: this.persona_info?.persona_id,
        persona:this.persona,
        cat_id:this.persona_info?.cat_id,
        type: t==='positive' ? 'approved' : 'disapproved'
      };
      this.Helper.trackEvents.customEvent("ticket_persona_answer_approvement", trackObj);
    },
    personaAnswerProcess(t){

      let trackObj = {
        impression_id:this.impression_id,
        persona_id: this.persona_info?.persona_id,
        persona:this.persona,
        cat_id:this.persona_info?.cat_id,
        type: t==='yes' ? 'approved' : 'disapproved'
      };
      this.Helper.trackEvents.customEvent("ticket_persona_approvement", trackObj);


      if(t==='yes'){
        this.showPersonaAnswer = true;
        this.showYeterlimi = true;
      } else if(t==='no'){


        this.personaLoading = true;

        this.api.content
          .getTicketCategoriesv2(null, null, this.persona_info.persona_id || -1)
          .then(({ data }) => {
            let result = data;
            if (result.success) {
              this.ticketCategories = result.data.ticket_categories;
              this.persona = result.data.persona;

              this.persona_info = result.data.persona_info;
              if(result.data.persona_info?.persona_id){
                this.showTicketCategories = false;
              } else {
                this.showTicketCategories = true;
              }

              let trackProps = {
                impression_id: this.impression_id,
                persona_id: this.persona_info?.persona_id,
                persona:this.persona,
                cat_id:this.persona_info?.cat_id
              }
              this.Helper.trackEvents.pageView(trackProps,'newticket_pre','v1');
            } else {
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
              this.$router.push(result.redirect_url);
            }
            this.personaLoading = false;
          })
          .catch(err => {
            this.personaLoading = false;
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });

      }

    },

    chooseFiles(id) {
      document.getElementById(id).click()
    },

    cancelUpload(timeStamp){
      this.cancelUploadRequest(parseInt(timeStamp));

      let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
      if(supportFileIndex !== -1){
        this.supportFiles.splice(supportFileIndex, 1);
      }
    },
    deleteFile(uploadID, type) {
      this.api.general.updateUploadStatus(uploadID, this.Constants.UPLOAD_STATUSES.DELETE)
        .then(({data}) => {
          let result = data;

          if (result.success) {
            if (type === this.Constants.UPLOAD_TYPES.TICKET) {
              this.supportFiles = this.supportFiles.filter(function (file) {
                return file.externalID !== uploadID
              });
            } else {
              this.files = this.files.filter(function (file) {
                return file.externalID !== uploadID
              });
            }

            this.$toasted.global.infoToast({description: result.message});
          } else {
            this.$toasted.global.errorToast({description: result.message});
          }
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
    },

    onFileChange(e, type) {

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.buttonLoading = true;

      let timeStamp = +new Date();


      let rand = Math.floor(Math.random() * 1000) + 1;
      timeStamp = timeStamp + rand;
      this.supportFiles.push({
        url: null,
        externalID: timeStamp,
        filename: files[0].name,
        stamp: timeStamp
      });

      this.uploadFileToGoogle(files[0], type, null, timeStamp)
        .then(result => {
          let supportFileIndex = this.supportFiles.findIndex(f => f.stamp === timeStamp);
          let supportFile = this.supportFiles[supportFileIndex];
          supportFile.externalID = result.externalID;
          supportFile.url = this.Constants.CDN_UPLOADS_G + result.uploadName,
            supportFile.icon_png = result.icon_png;
          this.supportFiles[supportFileIndex] = supportFile;


          this.buttonLoading = false;

        }).catch(err => {
        this.buttonLoading = false;

      });


      e.target.value = ''

    },

    deleteTicket(uuid){
      if (confirm(this.Constants.TEXTS.TICKET_DELETE_WARNING_DESC)) {
        this.api.general.deleteTicket(uuid, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.tickets = this.tickets.filter(function (ticket) {
                return ticket.uuid !== uuid;
              });

              this.$toasted.global.infoToast({description: result.message});
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          })
      }
    },

    selectTicketCategory: function(item) {
      this.selectedTicketCategoryId = item.id
      this.progressPercent = 33;
      if(this.selectedTicketCategoryId === '30' || this.selectedTicketCategoryId === '33'){
        this.orders = [];
        this.orderOffset = 0;
        this.orderType = 'buying';
        this.orderBodyLoaded = false;
        this.orderSeemoreLoader = false;
        this.orderTotalCount = 0;
        this.orderSeemorediv = false;
      } else if(this.selectedTicketCategoryId === '22'){
        this.orders = [];
        this.orderOffset = 0;
        this.orderType = 'selling';
        this.orderBodyLoaded = false;
        this.orderSeemoreLoader = false;
        this.orderTotalCount = 0;
        this.orderSeemorediv = false;
      }
    },
    cancelClick: function() {
      if(this.selectedRow === 'Yeni Destek Talebi Oluştur') {
          if(this.currentStep === 1){
            this.selectedRow = 'Tüm Destek Taleplerin';
            this.currentStep = 1;
            this.message = "";
            this.progressPercent = 0;
            this.selectedTicketCategoryId = null;
            window.scrollTo(0,0);
          } else {
            this.currentStep --;
            window.scrollTo(0,0);
          }

      }
    },
     continueClick() {
      if (this.currentStep === 1 && this.progressPercent === 0) {
        return;
      } else if (this.currentStep === 2 && this.message === "") {
        return;
      }

      if(this.currentStep === 2){
        this.createTicket();
      } else {
        this.pageLoaded = false;
        this.api.content
          .getTicketCategoriesv2(this.selectedTicketCategoryId, null)
          .then(({ data }) => {
            let result = data;
            if (result.success) {


              this.faqs = result.data.faqs;
              this.faqs.forEach(faq =>{
                let postObjImp = {
                  impression_id: this.impression_id,
                  persona_id: this.persona_info?.persona_id,
                  persona:this.persona,
                  page:this.$store.state.componentMap.page,
                  cat_id:this.selectedTicketCategoryId,
                  faq_id:faq.id
                }
                this.Helper.trackEvents.customEvent("faq_impressions", postObjImp);
              })
              this.persona = result.data.persona;
              this.retrieveOrders();
              window.scrollTo(0,0);

              let postObj = {
                cat_id:this.selectedTicketCategoryId,
                impression_id: this.impression_id,
                persona_id: this.persona_info?.persona_id,
                persona:this.persona
              }
              this.Helper.trackEvents.pageView(postObj,'newticket','v1');

            } else {
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
              this.$router.push(result.redirect_url);
            }
            this.currentStep++;
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });
      }
    },

    retrieveOrders(isFromSeeMore) {
      if (!isFromSeeMore) {
        this.orderOffset = 0;
        this.orderBodyLoaded = false;
      } else {
        this.orderSeemoreLoader = true
      }
      this.activeTabTimeStamp = + new Date();
      let tempTimeStamp = this.activeTabTimeStamp;
      return this.api.orders.retrieveOrders(this.orderType, null, 20, this.orderOffset,'', this.$Progress)
        .then(({data: result}) => {
          if (result.success && this.activeTabTimeStamp === tempTimeStamp) {
            this.orderOffset += result.data.orders.length;
            if (isFromSeeMore) {
              this.orders = this.orders.concat(result.data.orders);
            } else {
              this.orders = result.data.orders
            }

            this.orderTotalCount = result.data.orderCount;
            this.orderSeemorediv = result.data.seemorediv;
          } else {
            this.$toasted.global.errorToast({description: result.message});

          }
          this.orderBodyLoaded = true;
          return result
        })
        .catch(() => {
          this.orderBodyLoaded = true;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        })
        .finally(() => {
          this.orderBodyLoaded = true
          this.orderSeemoreLoader = false
        })
    },

    toggleOpen: function(faq) {
      if(this.selectedFaqId && this.selectedFaqId === faq.id){
        this.selectedFaqId = null;
      } else {
        let postObj = {
          cat_id:this.selectedTicketCategoryId,
          impression_id: this.impression_id,
          persona_id: this.persona_info?.persona_id,
          persona:this.persona,
          page:this.$store.state.componentMap.page,
          title:faq.title,
          faq_id:faq.id
        }
        this.Helper.trackEvents.customEvent("toggle_faq", postObj);
        this.selectedFaqId = faq.id;
      }
    },

    createTicket() {
      this.buttonLoading = true;
      let uploadIDs = this.supportFiles.map(function (file) {
        return file.externalID;
      });
      let description = this.message;

      let selectedTicketCategory = this.ticketCategories.find(({ id }) => id === this.selectedTicketCategoryId)
      let selectedTicketCategoryTitle = selectedTicketCategory && selectedTicketCategory.title ? selectedTicketCategory.title : 'Destek Talebim';

      this.api.general.createTicketV2(selectedTicketCategoryTitle, description, uploadIDs, this.selectedTicketCategoryId, this.persona, this.persona_info.persona_id, this.impression_id, this.$Progress)
        .then(({data}) => {
          let result = data;
          if (result.success) {
            this.$toasted.global.infoToast({description: result.message});
            this.buttonLoading = false;
            this.currentStep++;
            window.scrollTo(0,0);
          } else {
            this.$toasted.global.errorToast({description: result.message});
            this.buttonLoading = false;
          }
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.errorToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          this.buttonLoading = false;
        });
    },
    itemClick: function(title) {
      this.selectedRow = title;
      if (title === "Tüm Destek Taleplerin") {
        this.currentStep = 1;
        this.message = '';
        this.progressPercent = 0;
        this.getTickets();
      } else if(title === 'Yeni Destek Talebi Oluştur'){
        this.selectedFaqId = null;
        this.supportFiles = [];
        this.selectedTicketCategoryId = null;
        this.message = '';
        if(this.ticketCategories.length === 0){
          this.pageLoaded = false;
          this.api.content
            .getTicketCategoriesv2(null, null)
            .then(({ data }) => {
              let result = data;
              if (result.success) {
                this.ticketCategories = result.data.ticket_categories;
                this.persona = result.data.persona;

                this.persona_info = result.data.persona_info;
                if(result.data.persona_info?.persona_id){
                  this.showTicketCategories = false;
                }

                let trackProps = {
                  impression_id: this.impression_id,
                  persona_id: this.persona_info?.persona_id,
                  persona:this.persona,
                  cat_id:this.persona_info?.cat_id
                }
                this.Helper.trackEvents.pageView(trackProps,'newticket_pre','v1');
              } else {
                this.$toasted.global.errorToast({
                  description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
                });
                this.$router.push(result.redirect_url);
              }
              this.pageLoaded = true;
            })
            .catch(err => {
              this.pageLoaded = true;
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
            });
        }
      }
    },
    ticketClick: function(uuid) {
      this.$router.push('tickets/'+uuid);
    },
     getTickets(){
      this.pageLoaded = false;
      this.api.general
        .retrieveTickets()
        .then(({ data }) => {
          let result = data;
          if (result.success) {
            result.data.tickets.forEach(function(ticket) {
              ticket.visible = false;
            });

            this.tickets = result.data.tickets;
            this.pageLoaded = true;
          } else {
            this.$toasted.global.errorToast({ description: result.message });
          }
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.errorToast({
            description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
          });
        });

    }
  },

  computed: {
    getPersonaOption() {
      return this.persona_info.options.find(({ id }) => id === this.persona_info.selected_option)
    },
    navBarData() {

      if(this.selectedRow === 'Tüm Destek Taleplerin'){
        return {
          navBarList: [
            {
              title: "Destek",
              path: "/destek"
            },
            {
              title: "Destek Talepleri",
              path: "/tickets"
            }
          ],
          buttonTitle: "Yardım Merkezine Dön",
          buttonPath: "/destek"
        };
      } else {
        return {
          navBarList: [
            {
              title: "Destek",
              path: "/destek"
            },
            {
              title: "Destek Talepleri",
              path: "/tickets"
            },
            {
              title: "Yeni Destek Talebi",
              path: "/tickets"
            }
          ],
          buttonTitle: "Yardım Merkezine Dön",
          buttonPath: "/destek"
        };
      }
    }
  },
  watch:{

    'route.query.tab': function (newVal, oldVal) {
      if (newVal && newVal === 'new') {
        this.selectedRow = 'Yeni Destek Talebi Oluştur';
        this.selectedFaqId = null;
        this.supportFiles = [];
        this.selectedTicketCategoryId = null;
        this.message = '';
        if(this.ticketCategories.length === 0){
          this.pageLoaded = false;
          this.api.content
            .getTicketCategoriesv2(null, null)
            .then(({ data }) => {
              let result = data;
              if (result.success) {
                this.ticketCategories = result.data.ticket_categories;
                this.persona = result.data.persona;

                this.persona_info = result.data.persona_info;
                if(result.data.persona_info?.persona_id){
                  this.showTicketCategories = false;
                }

                let trackProps = {
                  impression_id: this.impression_id,
                  persona_id: this.persona_info?.persona_id,
                  persona:this.persona,
                  cat_id:this.persona_info?.cat_id
                }
                this.Helper.trackEvents.pageView(trackProps,'newticket_pre','v1');

              } else {
                this.$toasted.global.errorToast({
                  description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
                });
                this.$router.push(result.redirect_url);
              }
              this.pageLoaded = true;
            })
            .catch(err => {
              this.pageLoaded = true;
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
            });
        }
      } else {
        this.selectedRow = 'Tüm Destek Taleplerin';
        this.getTickets();
      }
    },

    "$store.state.temp_icon_png": function (newValue, oldValue) {
      this.supportFiles.forEach(function (file) {
        if(newValue[file.stamp]){
          file.icon_png = newValue[file.stamp];
        }
      });
    },
  },

  created() {
    this.impression_id = this.Helper.generateUUID();
    if (this.route.query.tab === 'new') {
      this.selectedRow = 'Yeni Destek Talebi Oluştur';
      this.selectedFaqId = null;
      this.supportFiles = [];
      this.selectedTicketCategoryId = null;
      this.message = '';
      if(this.ticketCategories.length === 0){
        this.pageLoaded = false;
        this.api.content
          .getTicketCategoriesv2(null, null)
          .then(({ data }) => {
            let result = data;
            if (result.success) {

              this.ticketCategories = result.data.ticket_categories;
              this.persona = result.data.persona;
              this.persona_info = result.data.persona_info;
              if(result.data.persona_info?.persona_id){
                this.showTicketCategories = false;
              }

              let trackProps = {
                impression_id: this.impression_id,
                persona_id: this.persona_info?.persona_id,
                persona:this.persona,
                cat_id:this.persona_info?.cat_id
              }
              this.Helper.trackEvents.pageView(trackProps,'newticket_pre','v1');

            } else {
              this.$toasted.global.errorToast({
                description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
              });
              this.$router.push(result.redirect_url);
            }
            this.pageLoaded = true;
          })
          .catch(err => {
            this.pageLoaded = true;
            this.$toasted.global.errorToast({
              description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE
            });
          });
      }
    } else {
      this.selectedRow = 'Tüm Destek Taleplerin';
      this.getTickets();
    }

  }
};
</script>

<style scoped lang="scss">


.hover-gg{
  color: #2D3640;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}


.persona-question-box{
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
  border: solid 1px #eaedf2;
  background-color: #ffffff;padding: 30px 29px 30px 30px;
  color: #00a575; font-size: 18px; font-weight: 600
}
.request-warning{

  width: 843px;
  margin-top: 35px;
  margin-bottom: 35px;
  border-radius: 10px;
  border: solid 1px rgba(242, 153, 51, 0.3);
  background-color: rgba(242, 153, 51, 0.2);

  .request-warning-inside{
    display: flex;
    padding: 20px 30px;
  }

  .warning-head{
    font-size: 16px;
    font-weight: normal;

    color: #2d3640;
  }
  .warning-sub-head{
    margin-bottom: 20px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 300;

    line-height: 1.14;

    color: #2d3640;
  }


  .warning-icon /deep/ {
    width: 35px;
    height: 32px;
    path {
      fill: #f29933;
    }
  }


  .disapproval-row{
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.5;
    color: #2d3640;
    .link{
      font-weight: bold;
      color: #fd4056;
    }
  }

}


.yeterlimi{
  margin-top: 60px;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 #2d36400c;
  border: solid 1px #eaedf2;
  background-color: #00a575;
  display: flex;
  align-items: center;

  .y-text{
    margin-left: 20px;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #ffffff;
    margin-right: 80px;
  }

  .y-button{
    margin-right: 30px;
    padding-left: 25px;
    padding-right: 25px;
    height: 44px;
    border-radius: 2px;
    border: solid 1px #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00a575;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    color: #ffffff;
    &:hover{
      background: #00a575!important;
      color: #ffffff;
      opacity: 0.8;
    }
  }

}



.support-icon /deep/{
  width: 24px;
  height: 24px;
  path {
    fill: #00a575;
  }
}

.support-icon-container {
  margin-left: 30px;
  min-width: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(253, 64, 86, 0.2);
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}

  .attach-icon /deep/ {
    width: 14px;
    height: 14px;
    path {
      fill: #2d3640;
    }
  }


  .delete-icon /deep/ {
    cursor: pointer;
    width: 14px;
    height: 14px;
    path {
      fill: #8b95a1;
    }
  }

  .delete-uploaded-file-img /deep/ {
    width: 18px;
    height: 24px;

    path {
      fill: #5e6b79;
    }
  }

.step1-card-item {
  width: 40.5%;
  display: flex;
  align-items: center;
  padding: 28px 25px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 30px;
  cursor: pointer;
}

.cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 33.5px;
  margin-right: 34px;
  border-radius: 2px;
  border: solid 1px rgba(45, 54, 64, 0.2);
  cursor: pointer;
}
.continue-button {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #ffffff;
  padding: 17px 0;
  border-radius: 2px;
  cursor: pointer;
  width: 220px;
}

.progress-bar-container {
  height: 15px;
  border-radius: 7.5px;
  background-color: #eaedf2;
  margin-bottom: 50px;
}

.progress-bar-inner {
  height: 15px;
  border-radius: 7.5px;
  background-color: #67cc64;
}

.text-area-description {
  margin-top: 35px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #5e6b79;
}
.add-attachment-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8b95a1;
  cursor: pointer;
  margin-left: 5px;
  &:hover{
    color: #2d3740;
  }
}
.attached-file-names {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #00a575;
  text-decoration: underline;
}

.ticket-submit-description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #5e6b79;
  margin-bottom: 50px;
  span{
    font-weight: bold;
  }
}

.ticket-summary-header {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2d3640;
  margin-bottom: 20px;
}

.ticket-summary-description {
  white-space: pre-line;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #8b95a1;
  padding: 25px;
  min-height: 200px;
}

.content-container {
  width: 843px;
  display: flex;
  flex-direction: column;
}
.percent-0 {
  width: 0;
  transition: all 0.4s;
}
.percent-33 {
  transition: all 0.4s;

  width: 33%;
}
.percent-66 {
  width: 66%;
  transition: all 0.4s;
}
.percent-100 {
  width: 100%;
  transition: all 0.4s;
}
.margin-right-57 {
  //margin-right: 57px;
}
.unselected-step1-data {
  border-radius: 5px;
  border: solid 1px #bfc8d2;
  background-color: #ffffff;
  color: #8b95a1;
}
.selected-step1-data {
  border-radius: 5px;
  border: solid 1px #67cc64;
  background-color: rgba(103, 204, 100, 0.1);
  color: #2d3640;
}
.unselected-continue-button {
  background-color: #bfc8d2;
}
.selected-continue-button {
  background-color: #00a575;
}
.help-problem-detail {

  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #2d3640;
  padding-top: 25px;
  padding-left: 25px;
  border-radius: 2px;
  background-color: #ffffff;
  min-height: 200px;
  resize: none;
  margin-bottom: 15px;
  width: 97%;
}
.help-problem-detail::placeholder {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8b95a1;
}
.empty-text-area {
  border: solid 1px rgba(75, 79, 82, 0.2);
}
.filled-text-area {
  border: solid 1px #00a575;
}

.selected-category-item {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2d3640;
  padding-right: 47px;
}
.category-item {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #8b95a1;
  padding-right: 50px;
}

.selected-category-row {
  border-color: #fd4056;
  border-style: solid;
  border-width: 0;
  border-right-width: 3px;
}
.ticket-subject-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: pre;
  color: #2d3640;
}
.disabled-text {
  color: #8b95a1 !important;
}
.answered-text {
  color: #00a575 !important;
}
.date-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5e6b79;
}
.chat-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #2d3740;
}
.chat-text-detail {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8b95a1;
}
.ticket-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-radius: 5px;
  border: solid 1px #eaedf2;
  background-color: #ffffff;
  margin-bottom: 20px;
  margin-right: 20px;
  height: 80px;
  cursor: pointer;
}

///////////

.menu-list {
  line-height: 1.25;
}

ul {
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

li:hover {
  display: list-item;
  text-align: -webkit-match-parent;
  color: #2d3640;
}

.menu-list li {
  cursor: pointer;
  border-radius: 2px;
  color: #4a4a4a;
  display: block;
  padding: 0.5em 0.75em;
  z-index: 99999;
}

.menu-list li:hover {
  border-radius: 2px;
  color: #2d3640;
  display: block;
  padding: 0.5em 0.75em;
  text-decoration: none;
}

.nav-items {
  padding-left: 20px;
}

/*ticket question*/
.ticket-question-body {
  margin-top: 40px;
  border: 1px solid rgb(234, 237, 242);
  padding: 20px;
}

.ticket-question-radio-div {
  margin-bottom: 10px;
  color: #2d3640;
  display: flex;
}

.categories-label {
  font-size: 15px;
  color: #2d3640;
  margin-left: 8px;
  display: unset;
  cursor: pointer;
}

label {
  margin-bottom: 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + .radio-label {
  margin-top: 1px;
  height: 26px;
  width: 16px;
  display: inline-block;
  padding: 0 0 0 0;
  cursor: pointer;
  margin-left: 10px;
  background: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-unselected.svg)
    no-repeat;
}

input[type="radio"]:checked + .radio-label {
  display: inline-block;
  cursor: pointer;
  margin-top: 1px;
  height: 26px;
  width: 16px;
  margin-left: 10px;
  background-image: url(https://gcdn.bionluk.com/site/icon/tansel_green_radio-button-selected.svg);
}

.ticket-question-categories {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid rgb(234, 237, 242);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ticket-question-header {
  margin-bottom: 24px;
  color: #2d3640;
  font-size: 18px;
  font-weight: 600;
}

.need-more-help-button {
  cursor: pointer;
  margin-top: 30px;
  width: 190px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8b95a1;
  background-color: #fff;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #2d3640;
  opacity: 0.9;
}

.uploadfile_text {
  float: left;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.uploadfile_text:hover {
  text-decoration: underline;
}

.progress-bar {
  width: 200px;
  margin: 20px 0 0;
}

/*loader part*/
@keyframes loading {
  10% {
    opacity: 0.4;
  }
  2% {
    opacity: 0.1;
  }
}

.ticket-loading {
  animation-name: loading;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.ticket-loader {
  min-width: 250px;
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background-color: #dfe4ec;
}

.technical-problem-container {
}

.technical-problem-header {
  margin-top: 15px;
  margin-bottom: 15px;
}


  .row-wrapper{
    width: 843px;
    height: 124px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
    &:hover{
      // opacity: 0.9;
    }
    .row-body{
      height: 84px;
      margin: 20px;
      display: flex;
      .row-item-image{
        width: 151px;
        height: 84px;
        border-radius: 5px;
        border: solid 1px #eff3f8;
        background-color: #f9f9f9;
        .info-icon-wrapper{
          width: 24px;
          height: 24px;
          opacity: 0.5;
          border-radius: 5px;
          background-color: #2d3640;
          cursor: pointer;
          &:hover{
            opacity: 1;
          }
          .info-icon /deep/{
            width: 12px;
            height: 12px;
            path{
              fill: #ffffff;
            }
          }
        }
      }

      .row-item-dashed{
        width: 200px;
        height: 84px;
        border-radius: 5px;
        border: dashed 1px #bfc8d2;
        background-color: rgba(244, 245, 247, 0.2);
        margin-left: 20px;

        .info-icon-wrapper-send-message{
          width: 24px;
          height: 24px;
          opacity: 0.5;
          border-radius: 5px;
          background-color: #2d3640;
          cursor: pointer;
          &:hover{
            opacity: 1;
          }
          .info-icon-message /deep/{
            width: 12px;
            height: 12px;
            path{
              fill: #ffffff;
            }
          }
        }

        .basket-wrapper{
          border-radius: 50%;
          background-color: #ffbf00;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          position: relative;
          .basket-icon /deep/{
            width: 16px;
            height: 14px;
            path{
              fill: #ffffff;
            }
          }
          .inner-icon-wrapper{
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: solid 1px #ffffff;
            background-color: #26ca9a;
            position: absolute;
            display: flex;
            align-items: center;
            bottom: -4px;
            right: -4px;
            .inner-icon /deep/ {
              width: 10px;
              height: 10px;
              path{
                fill: #ffffff;
              }
            }
          }
        }
        .order-date{
          margin-top: 2px;
          font-size: 12px;
          font-weight: normal;
          color: #5e6b79;
        }
      }
      .row-item-info{
        width: 178px;
        height: 84px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 24px;
        .order-price{
          width: 62px;
          height: 34px;
          font-size: 28px;
          font-weight: bold;
          color: #8b95a1;
          &.active{
            color: #39d1a2;
          }
        }
        .order-currency{
          font-size: 22px;
          font-weight: 500;
          color: #8b95a1;
          &.active{
            color: #39d1a2;
          }
        }
        .status-text{
          padding: 7px 15px;
          border-radius: 4px;
          font-size: 13px;
          font-weight: normal;
          text-align: center;
        }
      }
    }
  }
</style>
